package at.skimatec

import androidx.compose.runtime.*
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.url.URL
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import kotlin.js.Date

// netstat -ano | findstr :10004
// taskkill /PID 12356 /F

//https://medium.com/progressive-web-apps/im-trying-to-make-a-progressive-web-app-part-3-0-service-workers-5bf90180b109

//TODO: make a calendar icon <i class="fa-regular fa-calendar-days"></i>

//*************************
// Moritz Tablet
// innerWidth:  602 / 1007
// innerHeight: 839 / 433
//*************************

external fun getAppVersion(): String

var innerWidth = mutableStateOf(-1)
var innerHeight = mutableStateOf(-1)

fun main() {
    addServiceWorker()

    renderComposable(rootElementId = "content-root") {
        Body()
    }
}

//@JsExport
//fun jsMessageEvent(message: String) {
//    swinfo = message
//    console.log("*** jsMessageEvent message=", message)
//}

fun addServiceWorker() {
    val navigator = window.navigator

    if (navigator.serviceWorker != undefined) {
        navigator.serviceWorker.register("./sw.js").then(
            onFulfilled = { reg ->
                console.log("ServiceWorker registration successful with scope: ", reg.scope)

                reg.addEventListener("updatefound", {
                    //console.log("updatefound: ...")

                    val newSw = reg.installing

                    if (newSw != null) {
                        newSw.addEventListener("statechange", {
                            //console.log("updatefound state=" + newSw.state)
                        })
                    } else {
                        console.log("updatefound: NO new SW found!")
                    }
                })

//                it.addEventListener("message", {
//                    val msg = (it as MessageEvent).data.toString()
//                    console.log("*** EVENT 'message' = $msg")
//                    swinfo = msg
//                });
            },
            onRejected = {
                console.log("ServiceWorker registration failed: ", it.message)
            }
        )

//        navigator.serviceWorker.addEventListener("message", {
//            val msg = (it as MessageEvent).data as String
//
//            //console.log("*** EVENT c.msg=", msg)
//
//            if (msg == "reload") {
//                //console.log("********** RELOAD **********")
//                //window.location.reload()
//            }
//            //console.log("*** EVENT c.msg=", msg)
//        });
    } else {
        console.log("*** navigator.serviceWorker IS UNDEFINED!! ***")
    }
}

@Composable
fun Body() {
    var anrede: String by remember { mutableStateOf("") }
    var vorname: String by remember { mutableStateOf("") }
    var nachname: String by remember { mutableStateOf("") }
    var strasse: String by remember { mutableStateOf("") }
    var plz: String by remember { mutableStateOf("") }
    var ort: String by remember { mutableStateOf("") }
    var email: String by remember { mutableStateOf("") }
    var telefon: String by remember { mutableStateOf("") }
    var geburtstag: Date? by remember { mutableStateOf(null) }

    var emailIsInvalid: Boolean by remember { mutableStateOf(false) }
    var phoneIsInvalid: Boolean by remember { mutableStateOf(false) }

    var cityIsChanged: Boolean by remember { mutableStateOf(false) }

//    var confirmed: Boolean by remember { mutableStateOf(false) }

    val anredeList = mutableListOf<String>()
    anredeList.add("")
    anredeList.add("Herr")
    anredeList.add("Frau")

    var showDialog: Boolean by remember { mutableStateOf(false) }
    var dialogText: MutableList<String> = remember { mutableListOf() }
    var showSpinner: Boolean by remember { mutableStateOf(false) }
    var showAppVersion: Boolean by remember { mutableStateOf(false) }
    var showAppVersionCount: Int by remember { mutableStateOf(1) }

    var innerWidth: Int by remember { mutableStateOf(-1) }
    var innerHeight: Int by remember { mutableStateOf(-1) }

    var eventsRegistered: Boolean by remember { mutableStateOf(false) }

    fun setLocalStorage() {
        localStorage.setItem("anrede", anrede)
        localStorage.setItem("vorname", vorname)
        localStorage.setItem("nachname", nachname)
        localStorage.setItem("strasse", strasse)
        localStorage.setItem("plz", plz)
        localStorage.setItem("ort", ort)
        localStorage.setItem("email", email)
        localStorage.setItem("telefon", telefon)
        localStorage.setItem("geburtstag.tag", if (geburtstag != null) geburtstag?.getDate().toString() else "")
        localStorage.setItem("geburtstag.monat", if (geburtstag != null) geburtstag?.getMonth().toString() else "")
        localStorage.setItem("geburtstag.jahr", if (geburtstag != null) geburtstag?.getFullYear().toString() else "")
    }

    fun getLocalStorage() {
        try {
            anrede = localStorage.getItem("anrede") ?: ""
            vorname = localStorage.getItem("vorname") ?: ""
            nachname = localStorage.getItem("nachname") ?: ""
            strasse = localStorage.getItem("strasse") ?: ""
            plz = localStorage.getItem("plz") ?: ""
            ort = localStorage.getItem("ort") ?: ""
            email = localStorage.getItem("email") ?: ""
            telefon = localStorage.getItem("telefon") ?: ""

            val d = localStorage.getItem("geburtstag.tag")
            val m = localStorage.getItem("geburtstag.monat")
            val y = localStorage.getItem("geburtstag.jahr")

//            console.log("d=", d, d == null)
//            console.log("m=", m, m == null)
//            console.log("y=", y, y == null)

            if (d != "" && m != "" && y != "") {
//                console.log("SET BIRTHDATE!")
                geburtstag = Date(
                    y!!.toInt(),
                    m!!.toInt(),
                    d!!.toInt(),
                    0, 0, 0
                )
            } else {
                geburtstag = null
            }
        } catch (e: Exception) {
            console.log("getLocalStorage.error: " + e.message)
        }

        //console.log("getLocalStorage: anrede=$anrede vorname=$vorname")
    }

    if (!eventsRegistered) {
        eventsRegistered = true;

        window.addEventListener("resize", {
            innerWidth = js("window.innerWidth")
            innerHeight = js("window.innerHeight")

            //console.log("resize: innerWidth: $innerWidth   innerHeight: $innerHeight")
        })

        window.addEventListener("load", {
            innerWidth = js("window.innerWidth")
            innerHeight = js("window.innerHeight")

            //console.log("load: innerWidth: $innerWidth   innerHeight: $innerHeight")
        })

        getLocalStorage()
    }

    fun hasEmptyFields(): Boolean {
        return anrede.isBlank()
                || vorname.isBlank()
                || nachname.isBlank()
                || strasse.isBlank()
                || plz.isBlank()
                || ort.isBlank()
                || email.isBlank()
                || telefon.isBlank()
                || geburtstag == null
    }

    fun clearAllFields() {
        anrede = ""
        vorname = ""
        nachname = ""
        strasse = ""
        plz = ""
        ort = ""
        email = ""
        telefon = ""
        geburtstag = null

        cityIsChanged = false
        emailIsInvalid = false
        phoneIsInvalid = false

        dialogText.clear()

        showAppVersionCount = 1

        setLocalStorage()
    }

    fun send() {
        dialogText.clear()

        val url = if(window.location.href.lowercase().contains("localhost"))
            URL("http://127.0.0.1:10002/webreg")
        else
            URL("https://studio.skimatec.net/moritz/webreg")

        console.log("send.url = $url")

        //val url = URL("https://skimastudio.azurewebsites.net/api/moritz/web/webreg")

        val code = "kynGZQ7DlWRQHhFdDsKspSSgsW5vXI54i6qIl9MKZKUCUXYpa1epQQhs"

        val gender = if (anrede == "Herr") "M" else if (anrede == "Frau") "F" else ""

        val headers = Headers()
        headers.append("Content-Type", "application/json")

        val geburtstagText = "${geburtstag!!.getDate().toString().padStart(2, '0')}.${(geburtstag!!.getMonth() + 1).toString().padStart(2, '0')}.${geburtstag!!.getFullYear()}"

        window.fetch(
            url, RequestInit(
                method = "POST",
                body = JsonObject(
                    mapOf(
                        "Gender" to JsonPrimitive(gender),
                        "Firstname" to JsonPrimitive(vorname),
                        "Lastname" to JsonPrimitive(nachname),
                        "Street" to JsonPrimitive(strasse),
                        "Zip" to JsonPrimitive(plz),
                        "City" to JsonPrimitive(ort),
                        "EMail" to JsonPrimitive(email),
                        "Telephone" to JsonPrimitive(telefon),
                        "Birthdate" to JsonPrimitive(geburtstagText),
                        "Info" to JsonPrimitive(""),
                        "Code" to JsonPrimitive(code),
                        "Flags" to JsonPrimitive("INTERN")
                    )
                ),
                headers = headers
            )
        ).then(
            onFulfilled = {
                clearAllFields()
                dialogText.add("Daten wurden erfolgreich gesendet!")
                showDialog = true
            },
            onRejected = {
                dialogText.add("Fehler beim Senden der Daten: " + it.message)
                showDialog = true
            })
    }

    fun checkEMail(email: String): Boolean {
        val m = email.trim()

        if (m.isBlank())
            return true

        val count1 = m.count { it == '@' }
        if (count1 != 1)
            return false

        val idx1 = m.indexOf('@')

        val localPart = m.substring(0, idx1)

        val domainPart = m.substring(idx1 + 1);

        //console.log("localPart: $localPart; domainPart: $domainPart; email.count(): ${email.count()}")

        if (localPart.isBlank())
            return false

        if (domainPart.isBlank())
            return false

        if (localPart.endsWith('.') || localPart.endsWith('.'))
            return false

        if (localPart.indexOf("..") != -1)
            return false // ".." is possible, but not really a standard

        if (domainPart.startsWith('.') || domainPart.endsWith('.'))
            return false

        if (domainPart.indexOf('.') == -1)
            return false // no domain, because a domain has at minimum one point.

        if (domainPart.indexOf("..") != -1)
            return false // ".." is not possible in domain

        return true
    }

    fun deleteSpace(txt: String): String {
        var ret = ""

        for(c in txt) {
            if(c != ' ') {
                ret += c
            }
        }

        return ret
    }

    fun checkPhone(phoneNo: String): Boolean {
        // FORMAT: +43664/1234-1
        //
        //TODO: If '/' exists, check 4 digits before and after
        //TODO: If '-' exists, check 4 digits before and 1 digit after

        fun checkPhoneDigits(phone: String, separator: Char, digitsBefore: Int, digitsAfter: Int): Boolean {
            val separatorIdx = phone.indexOf(separator)

            //console.log("checkPhoneDigits: phone=$phone  separator=$separator  separatorIdx=$separatorIdx  digitsBefore=$digitsBefore  digitsAfter=$digitsAfter")

            if(separatorIdx > -1) {
                val before = phone.substring(0, separatorIdx)
                val after = phone.substring(separatorIdx + 1, phone.count())

                //console.log("checkPhoneDigits: before=$before  after=$after")
                //console.log("checkPhoneDigits: before.count()=${before.count()}  after=${after.count()}")

                if(before.count() < digitsBefore || after.count() < digitsAfter) {
                    //console.log("checkPhoneDigits: return false")
                    return false
                }
            }

            //console.log("checkPhoneDigits: return true")
            return true
        }

        val p = deleteSpace(phoneNo)

        //console.log("p=$p")

        val count1 = p.count { it == '+' }
        val count2 = p.count { it == '/' }
        val count3 = p.count { it == '-' }

        if (count1 > 1 || count2 > 1 || count3 > 1)
            return false

        for (c in p) {
            if (c != '+' &&
                c != '0' &&
                c != '1' &&
                c != '2' &&
                c != '3' &&
                c != '4' &&
                c != '5' &&
                c != '6' &&
                c != '7' &&
                c != '8' &&
                c != '9' &&
                c != '/' &&
                c != '-' &&
                c != ' '
            ) {
                return false
            }
        }

        var countDigits = 0
        for (c in p) {
            if (c == '0' ||
                c == '1' ||
                c == '2' ||
                c == '3' ||
                c == '4' ||
                c == '5' ||
                c == '6' ||
                c == '7' ||
                c == '8' ||
                c == '9'
            ) {
                ++countDigits
            }
        }

        //console.log("countDigits=$countDigits")

        // *** Check '+' ***
        val idx1 = p.indexOf('+')
        if (idx1 > -1 && idx1 != 0)
            return false

        // *** Check '/' ***
        val idx3 = p.indexOf('/')
        val idx5 = p.indexOf('-')

        //console.log("idx3=$idx3  idx5=$idx5")

        if(idx5 > -1 && idx5 < idx3)
            return false

        if(idx3 > -1) {
            var pno = p

            if(idx5 > -1)
                pno = p.substring(0, idx5)

            //console.log("idx3: pno=$pno")

            if(!checkPhoneDigits(pno, '/', 2, 4))
                return false
        }

//        if(separatorIdx > -1) {
//            val before = p.substring(0, separatorIdx)
//            val after = p.substring(separatorIdx + 1, p.count())
//
//            console.log("before=$before  after=$after")
//
//            if(before.count() < digitsBefore || after.count() < digitsAfter)
//                return false
//        } else if (countDigits < 6) {
//            return false
//        }

        // *** Check '-' ***

        if(idx5 > -1) {
            var pno = p

            if(idx3 > -1)
                pno = p.substring(idx3 + 1, p.count())

            //console.log("idx5: pno=$pno")

            if(!checkPhoneDigits(pno, '-', 4, 1))
                return false
        }

        if(countDigits < 6)
            return false

        return true
    }

    fun onOk() {
        dialogText.clear()

        if (hasEmptyFields())
            dialogText.add("Es sind nicht alle Felder ausgefüllt")

        if (phoneIsInvalid)
            dialogText.add("Falscher Wert für Telefon")

        if (emailIsInvalid)
            dialogText.add("Falscher Wert für E-Mail")

        if (dialogText.isNotEmpty()) {
            showDialog = true
            return
        }

//        if(!confirmed)
//            dialogText.add("Bitte Informationen zum Datenschutz lesen und bestätigen")

        if (dialogText.isNotEmpty()) {
            showDialog = true
            return
        }

        showSpinner = true
        send()
    }

    fun onZipEntered() {
        if (!cityIsChanged || ort.isBlank()) {
            if (plz == "4020" || plz == "4030" || plz == "4040") {
                ort = "Linz"
            } else if (plz == "4501") {
                ort = "Neuhofen an der Krems"
            } else if (plz == "4502") {
                ort = "St. Marien"
            } else if (plz == "4622") {
                ort = "Eggendorf"
            } else if (plz == "4540") {
                ort = "Bad Hall"
            } else if (plz == "4533") {
                ort = "Piberbach"
            } else if (plz == "4521" || plz == "4522") {
                ort = "Schiedlberg"
            } else if (plz == "4491") {
                ort = "Niederneukirchen"
            } else if (plz == "4492") {
                ort = "Hofkirchen im Traunkreis"
            } else if (plz == "4531" || plz == "4532" || plz == "4550") {
                ort = "Rohr im Kremstal"
            } else if (plz == "4052" || plz == "4053") {
                ort = "Ansfelden"
            } else if (plz == "4490") {
                ort = "St. Florian"
            } else {
                ort = ""
            }
        }
    }

    if (showDialog) {
        messageBox(
            messageList = dialogText,
            onDismiss = {
                showDialog = false
                showSpinner = false
            }
        )
    }

//    Div {
//        Text("Width: $innerWidth;   Height: $innerHeight")
//    }

//    Div(attrs = {
//        id("swinfo")
//    }) {
//        Text(swinfo)
//    }

//    Div {
//        Text("showAppVersionCount: $showAppVersionCount")
//    }

    Div(attrs = {
        classes("flex-row-wrap")
        classes("flex-align-middle")
        classes("gap-big")
    }) {
        Div(attrs = {
            classes("flex-cell-stretch")
        }) {
            Img(
                src = "img/moritzLogo.png",
                alt = "Moritz Logo",
                attrs = {
                    style {
                        //ratio: 1:2,47
                        //width(296.px)
                        //height(120.px)
                        width(17.cssRem)
                        height(7.cssRem)
                    }
                }
            )
        }

        Div(attrs = {
            classes("flex-cell-initial")
        }) {
            Div {
                Span(attrs = {
                    classes("headline-bold")
                    classes("disable-selection")
                    style {
                        fontSize(2.cssRem)
                        marginRight(0.5.cssRem)
                    }
                    tabIndex(-1)

                    onClick {
                        it.preventDefault()

                        if (showAppVersionCount >= 3) {
                            showAppVersion = true
                            showAppVersionCount = 0
                        } else {
                            showAppVersion = false
                            showAppVersionCount++
                        }
                    }
                }) {
                    Text("Deine Daten")
                }
            }

            if (showAppVersion) {
                Div {
                    Text("Version: ${getAppVersion()}")
                }

                Div {
                    htmlButton(
                        text = "Aktualisieren",
                        useStandardWidth = false,
                        onClick = {
                            //window.history.pushState(null, "", window.location.href)
                            window.location.reload()
                        }
                    )
                }

            }
        }
    }

    Div(attrs = {
        style {
            height(1.cssRem)
        }
    }) { }

    Div(attrs = {
        classes("flex-col")
        classes("gap-big")
        if (showSpinner) {
            classes("disabled")
        }
    }) {
        Div(attrs = {
            classes("flex-row-wrap")
            classes("gap-big")
        }) {
            selectField(label = "Anrede", value = anrede, list = anredeList, isCheckSelect = true, onInput = {
                anrede = it ?: ""
                setLocalStorage()
            })
            inputField(label = "Vorname", text = vorname, onInput = {
                vorname = it
                setLocalStorage()
            })
        }

        Div(attrs = {
            classes("flex-row-wrap")
            classes("gap-big")
        }) {
            inputField(label = "Nachname", text = nachname, onInput = {
                nachname = it
                setLocalStorage()
            })
            inputField(label = "Straße", text = strasse, onInput = {
                strasse = it
                setLocalStorage()
            })
        }

        Div(attrs = {
            classes("flex-row-wrap")
            classes("gap-big")
        }) {
            inputField(label = "PLZ", text = plz, isNumeric = true, onInput = {
                plz = it
                onZipEntered()
                setLocalStorage()
            })
            inputField(label = "Ort", text = ort, onInput = {
                ort = it
                cityIsChanged = true
                setLocalStorage()
            })
        }

        Div(attrs = {
            classes("flex-row-wrap")
            classes("gap-big")
        }) {
            inputField(label = "E-Mail", text = email, isInvalid = emailIsInvalid, isEMail = true, onInput = {
                email = it
                emailIsInvalid = !checkEMail(email)
                //console.log("emailIsInvalid: $emailIsInvalid")
                setLocalStorage()
            })
            inputField(label = "Telefon", text = telefon, isInvalid = phoneIsInvalid, isPhone = true, onInput = {
                telefon = it
                phoneIsInvalid = !checkPhone(telefon)
                //console.log("phoneIsInvalid: $phoneIsInvalid")
                setLocalStorage()
            })
        }

        Div(attrs = {
            classes("flex-row-wrap")
            classes("gap-big")
        }) {
            dateField(label = "Geburtstag", date = geburtstag, onInput = {
                geburtstag = it
                setLocalStorage()
            })
//            inputField(label="Standort", text = standort, onInput = { standort = it })
            Div(attrs = {
                classes("flex-cell-stretch")
                style {
                    minWidth(25.cssRem)
                }
            }) {
                Text(" ")
            }
        }
    }

//    Div(attrs = {
//        style {
//            height(2.cssRem)
//        }
//    }) { }

//    Div(attrs = {
//        if (showSpinner) {
//            classes("disabled")
//        }
//    }) {
//        Div(attrs = {
//            classes("inputfield-label")
//        }) {
//            Text("Datenschutz")
//        }
//
//        Div(attrs = {
//            style {
//                height(1.cssRem)
//            }
//        }) { }
//
//        Div(
//            attrs = {
//                classes("flex-row")
//                classes("gap-big")
//                classes("flex-align-middle")
//            }
//        ) {
//            Div {
//                checkField(
//                    label = "",
//                    checked = confirmed,
//                    onInput = { confirmed = it }
//                )
//            }
//
//            Div {
//                Text("Ich bin damit einverstanden, dass meine Daten zur weiteren Verarbeitung und Kontaktaufnahme durch die Fahrschule Moritz gespeichert werden. Unter fahrschule@moritz.at kann ich jederzeit die Verarbeitung widerrufen.")
//            }
//        }
//    }

    Div(attrs = {
        style {
            height(1.cssRem)
        }
    }) { }

    Div(attrs = {
        classes("flex-row")
        classes("gap-big")
    }) {
        Div(attrs = {
            classes("flex-cell-initial")
        }) {
            htmlButton(
                text = "Speichern",
                disabled = showSpinner,
                onClick = {
                    onOk()
                }
            )
        }

        if (showSpinner) {
            Div(attrs = {
                classes("flex-cell-initial")
                classes("spinner")
                style {
                    alignSelf(AlignSelf.Center)
                }
            }) {

            }
        }

        Div(attrs = {
            classes("flex-cell-stretch")
            classes("flex-cell-align-right")
        }) {
            htmlButton(
                text = "Felder löschen",
                useStandardWidth = false,
                disabled = showSpinner,
                onClick = {
                    clearAllFields()
                }
            )
        }
    }
}

