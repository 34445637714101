package at.skimatec

import androidx.compose.runtime.*
import org.jetbrains.compose.web.attributes.InputMode
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.js.Date

@Composable
fun htmlButton(
    text: String,
    onClick: (() -> Unit)?,
    disabled: Boolean = false,
    useStandardWidth: Boolean = true
) {
    Button(
        attrs = {
            onClick { _ ->
                if (!disabled)
                    onClick?.invoke()
            }
            classes("button")

            if (disabled) {
                classes("disabled")
            }

            style {
                if (useStandardWidth)
                    property("width", "120px")
                cursor("pointer")
            }
        },

        ) {
        Text(text)
    }
}

@Composable
fun htmlButton2(
    onClick: (() -> Unit)?,
    useStandardWidth: Boolean = true,
    content: @Composable () -> Unit
) {
    Button(
        attrs = {
            onClick { _ ->
                onClick?.invoke()
            }
            classes("button")
            style {
                if (useStandardWidth)
                    property("width", "120px")
                cursor("pointer")
            }
        })
    {
        content()
    }
}

@Composable
fun inputField(
    label: String,
    text: String,
    isInvalid: Boolean = false,
    isEMail: Boolean = false,
    isPhone: Boolean = false,
    isNumeric: Boolean = false,
    onInput: ((text: String) -> Unit)?
) {
    val colorLightRed = rgb(203, 86, 86)
    val colorLightBlue = rgb(59, 115, 204)

    Div(attrs = {
        classes("flex-cell-stretch")
        classes("flex-col")
        style {
            minWidth(25.cssRem)
        }
    }) {
        Div {
            Span(attrs = {
                classes("inputfield-label")
            }) {
                Text(label)
            }
        }

//        Div {
//            Text("isInvalid: $isInvalid")
//        }

        Div {
            TextInput(
                value = text,
                attrs = {
                    classes("inputfield-input")

                    if (isEMail) {
                        classes("transform-lowercase")
                    }

                    style {
                        border {
                            width(0.15.cssRem)
                            style(LineStyle.Solid)
                            //color(rgb(59, 115, 204))
                            color(if (isInvalid) colorLightRed else colorLightBlue)
                        }

                    }

                    if (isEMail) {
                        inputMode(InputMode.Email)
                    } else if (isPhone) {
                        inputMode(InputMode.Tel)
                    } else if (isNumeric) {
                        inputMode(InputMode.Numeric)
                    }

                    onInput {
                        onInput?.invoke(it.value)
                    }
                }
            )
        }
    }
}

@Composable
fun dateField(
    label: String,
    date: Date?,
    onInput: ((date: Date?) -> Unit)?,
    setValueImmediately: Boolean = false
) {
    var inputDate: Date? = date // by remember { mutableStateOf(date) }
    var showSelector by remember { mutableStateOf(false) }
    val months = arrayOf("not defined", "Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember")

    fun getCurDate(): String {
        if (inputDate != null)
            return "${inputDate!!.getDate()}. ${months[inputDate!!.getMonth() + 1]} ${inputDate!!.getFullYear()}"

        return ""
    }

    if (showSelector) {
        dateSelector(
            label = label,
            date = inputDate,
            months = months,
            onDismiss = {
                if (it) {
                    //console.log("inputDate:", inputDate)
                    onInput?.invoke(inputDate)
                }
                showSelector = false
            },
            onInput = {
                inputDate = it
                //console.log("setValueImmediately:", setValueImmediately)
                if (setValueImmediately) {
                    //console.log("setValueImmediately: Value SET!", inputDate)
                    onInput?.invoke(inputDate)
                }
            }
        )
    }

    Div(attrs = {
        classes("flex-cell-stretch")
        classes("flex-col")
        style {
            minWidth(25.cssRem)
        }
    }) {
        Div {
            Span(attrs = {
                classes("inputfield-label")
            }) {
                Text(label)
            }
        }

        Div(attrs = {
            classes("inputfield-input")
            onClick {
                showSelector = true
            }
        }) {
            val d = getCurDate()

            if (d == "") {
                DisposableEffect("") {
                    scopeElement.innerHTML = "&nbsp"
                    onDispose { }
                }
            } else {
                Text(d)
            }
        }
    }
}

@Composable
fun checkField(
    label: String,
    checked: Boolean,
    isSubComponent: Boolean = false,
    onInput: ((text: Boolean) -> Unit)?,
) {
    Div(attrs = {
        classes("flex-cell-stretch")
        classes("flex-row")
        classes("flex-align-middle")
    }) {
        Div {
            CheckboxInput(
                checked = checked,
                attrs = {
                    classes("checkfield-checkbox")
                    onInput {
                        onInput?.invoke(it.value)
                    }
                }
            )
        }

        if (label.isNotBlank()) {
            Div(attrs = {
                classes("flex-cell-initial")
            }) {
                Span(attrs = {
                    classes("inputfield-label")

                    if(isSubComponent) {
                        style {
                            fontWeight("normal")
                        }
                    }
                }) {
                    Text(label)
                }
            }
        }
    }
}

@Composable
fun selectField(
    label: String,
    value: String,
    list: MutableList<String>,
    isCheckSelect: Boolean = false,
    onInput: ((text: String?) -> Unit)?,
) {
    Div(attrs = {
        classes("flex-cell-stretch")
        classes("flex-col")
        style {
            minWidth(25.cssRem)
        }
    }) {
        if (label.isNotBlank()) {
            Div(attrs = {
                classes("flex-cell-initial")
            }) {
                Span(attrs = {
                    classes("inputfield-label")
                }) {
                    Text(label)
                }
            }
        }

        Div(attrs = {
            style {
                minWidth(25.cssRem)
            }
        }) {
            if (isCheckSelect) {
                Div(attrs = {
                    classes("flex-row")
                    classes("gap")
                    classes("inputfield-input")
                }) {
                    list.forEach { item ->
                        if(item.isNotBlank()) {
                            Div(attrs = {
                                classes("flex-cell-stretch")
                            }) {
                                checkField(label = item, checked = (value == item), isSubComponent = true, onInput = {
                                    if (it)
                                        onInput?.invoke(item)
                                })
                            }
                        }
                    }
                }
            } else {
                Select(
                    attrs = {
                        classes("inputfield-input")
                        onInput {
                            onInput?.invoke(it.value)
                        }
                    }
                ) {
                    list.forEach {
                        Option(
                            value = it,
                            attrs = {
                                if (value == it) {
                                    selected()
                                }
                            }
                        ) {
                            Text(it)
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun messageBox(
    message: String? = null,
    messageList: MutableList<String>? = null,
    onDismiss: (text: Boolean) -> Unit
) {
    Div(attrs = {
        classes("modal-dialog")
    }) {
        Div(attrs = {
            classes("modal-dialog-inner")
        }) {
            Div(attrs = {
                classes("modal-dialog-content")
            }) {
                Div(attrs = {
                    classes("text-center")
                    style {
                        padding(1.cssRem)
                    }
                }) {
                    Span {
                        if (message != null) {
                            Text(message)
                        } else if (messageList != null) {
                            for (msg in messageList) {
                                Div {
                                    Text(msg)
                                }
                            }
                        }
                    }
                }

                Div(attrs = {
                    classes("flex-cell-align-center")
                    style {
                        marginTop(1.cssRem)
                        marginBottom(1.cssRem)
                    }
                }) {
                    htmlButton(
                        text = "Ok",
                        onClick = {
                            onDismiss(true)
                        }
                    )
                }
            }
        }
    }
}

@Composable
fun dialog(
    onDismiss: (text: Boolean) -> Unit,
    content: @Composable () -> Unit
) {
    Div(attrs = {
        classes("modal-dialog")
    }) {
        Div(attrs = {
            classes("modal-dialog-inner")
        }) {
            Div(attrs = {
                classes("modal-dialog-content")
            }) {
                Div(attrs = {
                    classes("text-center")
                    style {
                        padding(1.cssRem)
                    }
                }) {
                    content()
                }

                Div(attrs = {
                    classes("flex-cell-align-center")
                    style {
                        marginTop(1.cssRem)
                        marginBottom(1.cssRem)
                    }
                }) {
                    htmlButton(
                        text = "Ok",
                        onClick = {
                            onDismiss(true)
                        }
                    )
                }
            }
        }
    }
}
