package at.skimatec

import androidx.compose.runtime.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun dateSelector(
    label: String,
    date: Date?,
    months: Array<String>,
    onInput: ((date: Date?) -> Unit)?,
    onDismiss: (ok: Boolean) -> Unit
) {
    var curDay: Int? by remember { mutableStateOf(date?.getDate()) }
    var curMonth: Int? by remember { mutableStateOf(if(date != null) date.getMonth() + 1 else null) }
    var curYear: Int? by remember { mutableStateOf(date?.getFullYear()) }

    val minimumAge = 13
    val countYears = 7
    val maxStartYear = Date().getFullYear() - minimumAge - countYears
    val minStartYear = maxStartYear - (countYears * 12)

    val colorLightBlue = rgb(59, 115, 204)

    val padding = 0.4.cssRem

    fun findCurrentStartYear(start: Int): Int {
        if (curYear == null)
            return start

        for (i in start downTo 0 step countYears) {
            //console.log("findCurrentStartYear: $i - ${i + countYears - 1}")
            if (curYear!! >= i && curYear!! <= i + countYears - 1)
                return i
        }

        return start
    }

    var startYear: Int by remember { mutableStateOf(findCurrentStartYear(maxStartYear)) }

    fun isSchaltjahr(): Boolean {
        if (curYear == null)
            return false

        var ret = false
        if (curYear!! % 4 == 0) {
            ret = true

            if (curYear!! % 100 == 0)
                ret = false

            if (curYear!! % 400 == 0)
                ret = true
        }

        return ret
    }

    fun checkDate(): Boolean {
        if (curDay == null || curMonth == null || curYear == null)
            return true

        if ((curMonth == 4 || curMonth == 6 || curMonth == 9 || curMonth == 11) && curDay!! > 30) {
            return false
        }

        if (curMonth == 2) {
            if (isSchaltjahr()) {
                if (curDay!! > 29)
                    return false
            } else {
                if (curDay!! > 28)
                    return false
            }
        }

        return true
    }

    fun isValidDate(): Boolean {
        return checkDate() && (curDay != null && curMonth != null && curYear != null)
    }

    fun getCurDate(): String {
        if (curDay == null || curMonth == null || curYear == null)
            return " eingeben"

        return if (checkDate()) {
            "$curDay. ${months[curMonth!!]} $curYear"
        } else {
            "Eingabefehler!"
//            "Falscher Tag für Monat $months[curMonth!!]!"
        }
    }

    fun setCurDate(force: Boolean = false) {
        if (curDay != null && curMonth != null && curYear != null) {
            //console.log("curDay:", curDay!!, "curMonth:", curMonth!!, "curYear:", curYear!!)
//            val dateString = "${curYear}-${curMonth.toString().padStart(2, '0')}-${curDay.toString().padStart(2, '0')}T00:00:00.000Z"
//            console.log("dateString: $dateString")
//            val d = Date.UTC(curYear!!, curMonth!!, curDay!!)

            var d = Date(curYear!!, curMonth!! - 1, curDay!!, 12, 0, 0, 0)

            //console.log("d.Month: " + d.getMonth() + "; d.UTCMonth: " + d.getUTCMonth())

            //console.log("setCurDate:", d)
            onInput?.invoke(d)
        } else {
            //console.log("setCurDate:", "null")
            onInput?.invoke(null)
        }
    }

    fun setDay(day: Int) {
        curDay = day
        setCurDate()
    }

    fun setMonth(month: Int) {
        curMonth = month
        setCurDate()
    }

    fun setYear(year: Int) {
        curYear = year
        setCurDate()
    }

    Div(attrs = {
        classes("modal-dialog")
    }) {
        Div(attrs = {
            classes("modal-dialog-inner")
        }) {
            Div(attrs = {
                classes("modal-dialog-content")
            }) {
                Div(attrs = {
                    classes("text-center")
                    classes("font-size-smaller")
                    style {
                        padding(padding)
                    }
                }) {

//    Div {
//        Text("maxStartYear: $maxStartYear | startYear: $startYear | curYear: $curYear" )
//    }

                    Div(attrs = {
                        classes("flex-col")
                        classes("gap-big")
                    }) {
                        Div(attrs = {
                            classes("colorBackgroundLightBlue")
//                            classes("font-size-big")
                            style {
                                padding(padding)
                            }
                        }) {
                            Text("$label: ")
                            Span(attrs = {
                                if (!checkDate()) {
                                    classes("colorBackgroundLightRed")
                                }
                            }) {
                                Text(getCurDate())
                            }
                        }

                        Div {
                            for (i in 0..<4) {
                                Div(attrs = {
                                    classes("flex-row-wrap")
                                }) {
                                    for (x in 1..<9) {
                                        val day = (i * 8) + x

                                        Div(attrs = {
                                            classes("cursor-hand")
                                            classes("flex-cell-stretch")
                                            classes("disable-selection")

                                            if (day == curDay) {
                                                if (checkDate()) {
                                                    classes("colorBackgroundYellow")
                                                    classes("colorBlue")
                                                } else {
                                                    classes("colorBackgroundLightRed")
                                                }
                                            }
                                            style {
                                                padding(padding)
                                                if (day <= 31) {
                                                    border(0.15.cssRem, LineStyle.Solid, colorLightBlue)
                                                } else {
                                                    border(0.15.cssRem, LineStyle.Solid, Color.transparent)
                                                }
                                                margin(0.15.cssRem)
                                            }

                                            tabIndex(-1)

                                            onClick {
                                                it.preventDefault()
                                                if (day <= 31) {
                                                    setDay(day)
                                                }
                                            }
                                        }) {
                                            if (day <= 31) {
                                                Text(day.toString())
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        Div {
                            for (i in 0..<2) {
                                Div(attrs = {
                                    classes("flex-row-wrap")
                                }) {
                                    for (x in 1..<7) {
                                        val month = (i * 6) + x

                                        Div(attrs = {
                                            classes("cursor-hand")
                                            classes("flex-cell-stretch")
                                            classes("disable-selection")

                                            if (month == curMonth) {
                                                classes("colorBackgroundYellow")
                                                classes("colorBlue")
                                            }

                                            style {
                                                padding(padding)
                                                border(0.15.cssRem, LineStyle.Solid, colorLightBlue)
                                                margin(0.15.cssRem)
                                            }

                                            tabIndex(-1)

                                            onClick {
                                                it.preventDefault()
                                                setMonth(month)
                                            }
                                        }) {
                                            Text(months[month])
                                        }
                                    }
                                }
                            }
                        }

                        Div(attrs = {
                            classes("flex-row")
                        }) {
                            Div(attrs = {
                                classes("flex-cell-initial")
                                classes("flex-cell-align-middle")
                                classes("flex-cell-align-center")
                                classes("cursor-hand")
//                                classes("font-size-big")

                                if (startYear == minStartYear) {
                                    classes("disabled")
                                }

                                style {
                                    width(4.cssRem)
                                    margin(0.15.cssRem)
                                    backgroundColor(colorLightBlue)
                                }
                                onClick {
                                    if (startYear > minStartYear) {
                                        startYear -= countYears
                                    }
                                }
                            }) {
                                DisposableEffect("") {
                                    scopeElement.innerHTML = "<i class='fa-solid fa-chevron-left'></i>"
                                    onDispose {  }
                                }
                            }

                            Div(attrs = {
                                classes("flex-cell-stretch")
                            }) {
                                Div(attrs = {
                                    classes("flex-row-wrap")
                                }) {
                                    for (i in startYear..<startYear + countYears) {
                                        Div(attrs = {
                                            classes("cursor-hand")
                                            classes("flex-cell-stretch")
                                            classes("disable-selection")

                                            if (i == curYear) {
                                                classes("colorBackgroundYellow")
                                                classes("colorBlue")
                                            }

                                            style {
                                                padding(padding)
                                                border(0.15.cssRem, LineStyle.Solid, colorLightBlue)
                                                margin(0.15.cssRem)
                                            }
                                            tabIndex(-1)
                                            onClick {
                                                it.preventDefault()
                                                setYear(i)
                                            }
                                        }) {
                                            Text(i.toString())
                                        }
                                    }
                                }
                            }

                            Div(attrs = {
                                classes("flex-cell-initial")
                                classes("flex-cell-align-middle")
                                classes("flex-cell-align-center")
                                classes("cursor-hand")
//                                classes("font-size-big")

                                if (startYear == maxStartYear) {
                                    classes("disabled")
                                }

                                style {
                                    width(4.cssRem)
                                    margin(0.15.cssRem)
                                    backgroundColor(colorLightBlue)
                                }
                                onClick {
                                    if (startYear < maxStartYear) {
                                        startYear += countYears
                                    }
                                }
                            }) {
//                                Text("<")

                                DisposableEffect("") {
                                    scopeElement.innerHTML = "<i class='fa-solid fa-chevron-right'></i>"
                                    onDispose {  }
                                }
                            }

                        }

                        Div(attrs = {
                            classes("flex-cell-align-center")
                            classes("flex-row")
                            classes("gap-big")
                            style {
                                marginTop(0.25.cssRem)
                            }
                        }) {
                            Div {
                                htmlButton2(
                                    onClick = {
                                        if(isValidDate()) {
                                            onDismiss(true)
                                        }
                                    }
                                ) {
                                    Span(attrs = {
                                        if(!isValidDate()) {
                                            classes("disabled")
                                        }
                                    }) {
                                        Text("OK")
                                    }
                                }
                            }

                            Div {
                                htmlButton2(
                                    onClick = {
                                        onDismiss(false)
                                    }
                                ) {
                                    Span {
                                        Text("Abbrechen")
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}